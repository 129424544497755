import Rebase from 're-base';
import firebase from 'firebase';

const firebaseApp = firebase.initializeApp({
	apiKey: 'AIzaSyAfb__eP20j8DG3ZI_yJr21wu9Ba4VxGDU',
	authDomain: 'catch-of-the-day---john-hicks.firebaseapp.com',
	databaseURL:
		'https://catch-of-the-day---john-hicks-default-rtdb.firebaseio.com',
	// projectId: 'catch-of-the-day---john-hicks',
	// storageBucket: 'catch-of-the-day---john-hicks.appspot.com',
	// messagingSenderId: '680367118189',
	// appId: '1:680367118189:web:6127005b41f4d270eeac64',
	// measurementId: 'G-SK1T8PWKJ7',
});

const base = Rebase.createClass(firebaseApp.database());

export { firebaseApp };

export default base;
